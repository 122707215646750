import React from 'react';
import Layout from 'components/Layout';
import Article from 'components/Article';
import Hero from 'components/Hero';
import ContactMe from 'components/ContactMe' ;

const Thankyou = () => {
  return (
    <Layout>
      <Hero 
        title="Thank you"        
      ></Hero>
      <Article>
      <h1>Thank you</h1>
      <p>Your message is on the way to our inbox. We will get back to you as soon as possible.</p>
      </Article>
      <ContactMe></ContactMe>
    </Layout>
  );
;}

export default Thankyou
